import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import css from './styles.module.scss'
import Title from '../Title'
import ButtonLink from '../ButtonLink'
import FullWidthBackground from '../FullWidthBackground'

// component
// ==========================================================================================
const ApplyBanner = ({
  applicationHeading,
  applicationCta,
  applicationLink,
}) => {
  const [isVisible, setVisible] = useState(false)

  const handleScroll = () => {
    setTimeout(() => {
      setVisible(true)
    }, 500)
  }
  // show banner on first scroll
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true,
      once: true,
    })
    // cleanup fn
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <section
      className={classNames(css.wrap, {
        [css.isVisible]: isVisible,
      })}
    >
      <FullWidthBackground isLight className={css.background} />
      <div className="row">
        <div className="col-sm-12 col-md-10 offset-md-1">
          <div className={css.inner}>
            <Title tag="h5" content={applicationHeading} className={css.copy} />
            <ButtonLink {...applicationLink} className={css.button}>
              <span className={css.buttonCopy}>{applicationCta}</span>
            </ButtonLink>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ApplyBanner
