import React from 'react'
// import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { get } from 'lodash'

import Hero from '../components/Hero'
import Slices from '../components/Slices'
import ApplyBanner from '../components/ApplyBanner'
import ApplySection from '../components/ApplySection'

export const query = graphql`
  query InvestmentQuery($lang: String) {
    prismic {
      data: allInvestments(lang: $lang) {
        edges {
          node {
            meta: _meta {
              id
              type
              uid
              lang
              alternateLanguages {
                uid
                lang
                type
              }
            }
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image
            heroHeading: hero_heading
            heroBody: hero_body
            hero_image1
            heroImage1Sharp: hero_image1Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            hero_image2
            heroImage2Sharp: hero_image2Sharp {
              childImageSharp {
                fluid(maxWidth: 850) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
            applicationHeading: application_heading
            applicationCta: application_cta
            applicationBlock: application_block
            applicationLink: application_link {
              _linkType
              ... on PRISMIC__ExternalLink {
                url
              }
            }
            slices {
              ... on PRISMIC_InvestmentSlicesTopic {
                type
                primary {
                  heading
                }
                faq: fields {
                  question
                  answer
                }
              }
            }
          }
        }
      }
    }
  }
`

const PortfolioContainer = (props) => {
  const data = get(props, 'data.prismic.data.edges[0].node')
  return (
    <>
      <Hero {...data} />
      {/* wrapper for sticky banner */}
      <div style={{ position: 'relative' }}>
        <Slices {...data} />
        <ApplyBanner {...data} />
      </div>
      <ApplySection {...data} />
    </>
  )
}

// PortfolioContainer.propTypes = {
//   data: PropTypes.shape({
//     prismic: PropTypes.shape({
//       data: PropTypes.shape({}).isRequired,
//     }).isRequired,
//   }).isRequired,
// }

export default PortfolioContainer
