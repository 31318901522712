import React from 'react'
// import { useInView } from 'react-intersection-observer'

import css from './styles.module.scss'
import { ReactComponent as Arrow } from '../../images/arrow-right.svg'
import Link from '../Link'
import ColorChangingBackground from '../ColorChangingBackground'
// import { StateContext } from '../../context/GlobalStateContext'

// component
// ==========================================================================================
const ApplySection = ({ applicationBlock, applicationLink }) => {
  // const [ref, inView] = useInView({ threshold: 0.2 })
  // const [, dispatch] = useContext(StateContext)

  // useEffect(() => {
  //   dispatch({
  //     type: 'setApplyBannerVisible',
  //     updates: { isApplyBannerVisible: !inView },
  //   })
  // }, [dispatch, inView])

  return applicationBlock ? (
    <ColorChangingBackground className={css.wrap} noFadeOut>
      <div className="row">
        <div className="col-sm-12 col-md-10 offset-md-1">
          <Link link={applicationLink} className={css.link}>
            {applicationBlock.split(' ').map((word) => (
              <span className={css.word} key={word}>
                {word}
              </span>
            ))}
            <Arrow className={css.arrow} />
          </Link>
        </div>
      </div>
    </ColorChangingBackground>
  ) : null
}

export default ApplySection
